<template>
    <div class="disturb">
        <div class="d-flex justify-content-between align-items-center my-2">
            <h6>
                Не беспокоить
            </h6>
            <b-button @click="openDisturbModal" v-if="loanTags.buttons.create">
                Создать запись
            </b-button>
        </div>
        <b-table
            :items="loanTags.items"
            :fields="disturbHeaders"
        >
            <template #cell(delete)="data">
                <span class="disturb__button" @click="onDelete(data.item.id)">X</span>
            </template>
        </b-table>
        <b-modal
            id="disturbModal"
            hide-footer
            @close="onReset"
        >
            <template #modal-title>
                <div>
                    Создать запись
                </div>
            </template>
            <b-overlay
                :show="request" 
                rounded="sm"
                
            >
            <b-form
                novalidate 
                autocomplete="off" 
                @submit="onSubmit"
            >
                <b-form-group
                        label="Дата окончания"
                >
                <b-form-datepicker
                 v-model="form.expiresAt" 
                 placeholder="Выберите дату"
                 locale="ru"
                 :min="minDate"/>
                </b-form-group>
                <b-form-group
                    label="Причина"
                >
                    <b-form-select
                        placeholder="Выберите причину"
                        v-model="form.reason"
                        :options="loanTags.enums.reasons"
                        required
                    />
                </b-form-group>
                <b-button :disabled="request" type="submit">
                        Сохранить
                </b-button>
                
            </b-form>
            </b-overlay>
    </b-modal>
    </div>

</template>

<script>
    export default {
        data(){
            return{
                disturbHeaders: [
                    {
                        key: "startedAt",
                        label: "Дата создания",
                        formatter(value) {
                            return new Date(value).toLocaleDateString();
                        },
                    },
                    {
                        key: "expiresAt",
                        label: "Дата окончания",
                        formatter(value) {
                            return new Date(value).toLocaleDateString();
                        }
                    },
                    {
                        key: "reason",
                        label: "Причина",
                    },
                    {
                        key: "user.name",
                        label: "Создатель",
                    },
                ],

                request: false,
                form: {
                    expiresAt: null,
                    reason: "",          
                }
            }
        },
        computed:{
            minDate(){
                return new Date();
            }
        },
        mounted(){
            if(this.loanTags.buttons.delete){
                this.disturbHeaders.push({
                        key: "delete",
                        label: "Удалить"
                })
            }
        },
        methods: {
            openDisturbModal() {
                this.onReset()
                this.$bvModal.show("disturbModal");
                return false;
            },
            onDelete(id){

                if (this.request) {
                    return;
                }

                this.request = true;

                this.jsonRpc("admin.loan.tags.delete", {id: id})
                .then(() => {
                    this.$alertify.success("Запись успешно удалена");
                })
                .then(() => {
                    this.$emit("updateData")
                })
                .finally(async () => {
                    this.request = false;
                });
            },
            onSubmit(event){
                event.preventDefault();

                if (this.request) {
                    return;
                }

                if(!this.form.expiresAt || !this.form.reason){
                    return;
                }

                this.request = true;

                this.jsonRpc("admin.loan.tags.create", {loan_id: parseInt(this.$route.params.id), ...this.form})
                .then(() => {
                    this.$alertify.success("Запись добавлена");
                    this.$bvModal.hide("disturbModal");
                    this.onReset();
                })
                .then(() => {
                    this.$emit("updateData")
                })
                .finally(async () => {
                    this.request = false;
                });
            },
            onReset(){
                this.form = {
                    expiresAt: null,
                    reason: ""
                }
            }
        },
        props: {
            loanId: {
                default: 0,
            },
            loanTags: {
                required: true,
                default: () => {}
            }
        }
    }
</script>

<style>
.disturb {
    cursor: default;
}
.disturb__button:hover {
    cursor: pointer;
}
</style>