<template>
    <div class="bannerDisturb" v-if="isShow">
        внимание! проставлена метка "не беспокоить" до {{ new Date(loanTags.items[0].expiresAt).toLocaleDateString() }}, 
        причина: {{loanTags.items[0].reason}}, создатель: {{ loanTags.items[0].user.name }}
    </div>

</template>

<script>
    export default {
        computed: {
            isShow(){
                return Boolean(new Date(this.loanTags.items[0].expiresAt) > new Date())
            }
        },
        props: {
            loanTags: {
                required: true,
                default: () => {}
            }
        }
    }
</script>

<style>
    .bannerDisturb {
        text-transform: uppercase;
        color: white;
        background-color: red;
        display: inline-block;
        align-items: center;
        border: 4px solid black;
        padding: 5px 10px;
        box-sizing: border-box;
    }
</style>